<template>
  <div>
    <top-menu></top-menu>
    <section id="container">
      <left-menu></left-menu>
      <section id="content">
        <section class="studybox_area">
          <div class="studybox_contact_btn"><img src="/image/common/top_ic_contact.png" alt="상담하기" /></div>
          <div class="studybox_h2_box">
            <h2>로그인</h2>
          </div>
          <section class="study_myinfo_list_area" style="margin-top:-16px;" >
            <div style="width:90%;margin:auto;">
              <section class="study_main_box">
                <div style="width:100%;">
                  <div class="signup_left_box"></div>
                  <div class="signup_right_box">
                    <div id="login_content">
                      <!-- 로그인 폼 시작 -->
                      <form class="login_form" alt="로그인">
                        <div class="login_form_box">
                          <input type="email" v-model="loginForm.email" class="login_input_box_01_round moon_email" placeholder="e-mail 주소" alt="이메일 주소" style="margin-bottom:10px" @keyup.enter="login">
                          <input type="password" v-model="loginForm.password" class="login_input_box_01_round moon_pass" placeholder="비밀번호" alt="비밀번호 입력" @keyup.enter="login">
                        </div>
                      </form>
                      <div style="width:230px;margin:auto;text-align:center;">
                        <button type="button" class="btn loginBtn_sheet" @click.prevent="login">
                          <a href="#">로그인</a>
                        </button>
                        <br/>
                        <br/>
                        <button type="button" class="btn passwordBtn_sheet" @click.prevent="$router.push({ name: 'forgot-password' })" style="padding-left:22px;">
                          <a href="#">비밀번호를 잃어버렸어요!</a>
                        </button>
                        <br/>
                        <div class="studybox_h2_box clickable" @click.prevent="$router.push({ name: 'register' })" style="
                            margin: 0px auto;
                            width: 172px;
                            text-align: center;
                        ">
                          <h2>회원가입</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </section>
        <footer-navigation :back="{ name: 'home' }"></footer-navigation>
        <content-footer></content-footer>
      </section>
    </section>
    <main-footer></main-footer>
  </div>
</template>

<script>

import useJwt from '@/auth/jwt/useJwt'
import LeftMenu from '@/layouts/components/Menu/LeftMenu'
import TopMenu from '@/layouts/components/Menu/TopMenu'
import FooterNavigation from '@/layouts/components/FooterNavigation'
import MainFooter from '@/layouts/components/Footer'
import ContentFooter from '@/layouts/components/ContentFooter'

export default {
  name: 'Login',
  components: {
    LeftMenu,
    TopMenu,
    FooterNavigation,
    MainFooter,
    ContentFooter
  },
  data () {
    return {
      loginForm: {
        email: typeof this.$route.query.email !== 'undefined' ? this.$route.query.email : '',
        password: ''
      },

      passwordFieldType: 'password'
    }
  },
  methods: {
    async login () {
      try {
        const response = await useJwt.login(this.loginForm)

        const { userData } = response.data
        useJwt.setToken(response.data.accessToken)
        useJwt.setRefreshToken(response.data.refreshToken)
        localStorage.setItem('userData', JSON.stringify(userData))
        this.$ability.update(userData.ability)

        if (userData.enrollment) {
          await this.$router.replace({ name: 'study-room' })
        } else {
          await this.$router.replace({ name: 'program.level' })
        }

        this.$store.dispatch('TOGGLE_IS_LOGGED_IN', true)
        this.$store.dispatch('SET_USER_DATA', userData)

        console.log(`Welcome ${userData.name || userData.email}`)
        console.log(`You have successfully logged in as ${userData.role}. Now you can start to explore!`)
        console.log(userData)
      } catch (e) {
        this.$swal({
          title: 'Error',
          text: e.response.data,
          icon: 'error'
        })
      }
    },

    togglePasswordVisibility () {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    passwordToggleIcon () {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  }
}
</script>

<style scoped>

</style>
